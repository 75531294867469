import React from "react"
import PropTypes from "prop-types"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import { HeaderDeleteIcon } from "../Icons/Icons"
import delIcon from "../../Assets/Images/delete_icon.png"
import susIcon from "../../Assets/Images/suspend_icon.png"

const DeleteModal = ({ show, onDeleteClick, onCloseClick, suspend, page }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-5 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <img
                src={suspend ? susIcon : delIcon}
                height="70px"
                width="70px"
              />
              {suspend ? (
                <h5 style={{ marginTop: "20px" }}>
                  Are you sure you want to suspend the user?
                </h5>
              ) : (
                <h5>
                  {page == "offer"
                    ? "Are you sure you want to delete this offer?"
                    : page == "user"
                    ? "Are you sure you want to delete user?"
                    : page == "moderator"
                    ? "Are you sure, you want to delete moderator?"
                    : "Are you sure you want to delete this discovery?"}
                </h5>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3 modalButtons">
              <button
                type="button"
                className="btn cancelButton"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn deleteButton"
                style={{ backgroundColor: "#6f38c5" }}
                onClick={onDeleteClick}
              >
                {suspend ? "Suspend" : "Delete"}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  suspend: PropTypes.any,
  page: PropTypes.any,
}

export default DeleteModal
