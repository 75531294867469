import adminDataObject from "pages/Components/Prizedly-Controller/Controller"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Label, Input, Card, Button } from "reactstrap"
import Swal from "sweetalert2"
import "../User Management/UserManagement.css"
import loader from "../../Assets/Images/loader.gif"
import "../../../../App.css"
import CountriesSearchbar from "../Common/Country_Searchbar/CountriesSearchbar"

function AddModerator() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [geoList, setGeoList] = useState([])
  const [geolocation, setGeolocation] = useState({})
  const [emailError, setEmailError] = useState("")
  const [nameError, setNameError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [geolocationError, setGeolocationError] = useState("")
  const [loading, setLoading] = useState(false)

  let nameErrorCheck = false,
    emailErrorCheck = false,
    passwordErrorCheck = false,
    geoErrorCheck = false

  const history = useHistory()
  const addUserHandler = () => {
    const emailRegex =
      /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i
    const userRegex = /^[a-zA-Z ]{2,40}$/
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

    if (email === "") {
      setEmailError("Please enter the email id")
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter the valid email id")
    } else {
      setEmailError("")
      emailErrorCheck = true
    }
    if (name === "") {
      setNameError("Please enter the user's name")
    } else if (!userRegex.test(name)) {
      setNameError("Please enter the valid name")
    } else {
      setNameError("")
      nameErrorCheck = true
    }
    if (password === "") {
      setPasswordError("Please enter a password")
    } else if (!passwordRegex.test(password)) {
      setPasswordError(
        "Password must contain at least 8 characters including 1 uppercase letter, 1 lowercase letter, 1 special character and 1 numeric value."
      )
    } else {
      setPasswordError("")
      passwordErrorCheck = true
    }
    if (geolocation === {}) {
      setGeolocationError("Please select at least one geolocation")
    } else {
      setGeolocationError("")
      geoErrorCheck = true
    }

    if (
      nameErrorCheck &&
      emailErrorCheck &&
      passwordErrorCheck &&
      geoErrorCheck
    ) {
      setLoading(true)

      const moderatorDetails = {
        name: name,
        email: email,
        password: password,
        geoLocations: JSON.stringify(geolocation),
      }
      adminDataObject.addModerator(moderatorDetails, result => {
        if (result.data.status === true) {
          setLoading(false),
            Swal.fire({
              title: "",
              text: `User added successfully`,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            }),
            history.push("/admin_moderator_management")
        } else {
          setLoading(false),
            Swal.fire({
              title: "",
              text: result.data.message,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            }),
            Swal.fire({
              title: "",
              text: `Invalid Credentials`,
              icon: "warning",
              timer: 2000,
              showConfirmButton: false,
              showConfirmButton: false,
            })
        }
      })
    }
  }

  const onCancelHandler = () => {
    history.push("/admin_moderator_management")
  }

  const countryListUpliftHandler = (val, viewGeo) => {
    console.log("discoveryGeoData==>", val, "@array==>", viewGeo)
    setGeoList(viewGeo)
    setGeolocation(val)
  }

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <>
      {loading ? (
        <div className="backdrop">
          <img src={loader} height="200px" width="200px" />
        </div>
      ) : (
        <div className={`main-content${classAlign}`}>
          <div
            className="page-content"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Card className="addUserCard">
              <div className="addUserFormContainer ">
                <div className="addInputField">
                  <Label>Name</Label>
                  <Input
                    className="addUserInput"
                    type="text"
                    onChange={e => setName(e.target.value)}
                    value={name}
                  />
                  {nameError && (
                    <p
                      className="validationMsg"
                      // style={{ color: "red", marginBottom: "0px" }}
                    >
                      {nameError}
                    </p>
                  )}
                </div>
                <div className="addInputField">
                  <Label>Email</Label>
                  <Input
                    className="addUserInput"
                    type="email"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                  />
                  {emailError && (
                    <p
                      className="validationMsg"
                      // style={{ color: "red", marginBottom: "0px" }}
                    >
                      {emailError}
                    </p>
                  )}
                </div>
                <div className="addInputField">
                  <Label>Password</Label>
                  <Input
                    className="addUserInput"
                    type="password"
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                  />
                  {passwordError && (
                    <p
                      className="validationMsg"
                      // style={{ color: "red", marginBottom: "0px" }}
                    >
                      {passwordError}
                    </p>
                  )}
                </div>
                <CountriesSearchbar handler={countryListUpliftHandler} />
              </div>
              <div className="addUserButtonContainer">
                <button
                  className="addUserCancelButton"
                  onClick={onCancelHandler}
                >
                  Cancel
                </button>
                <button className="addUserSaveButton" onClick={addUserHandler}>
                  Save
                </button>
              </div>
              <br />
              <br />
            </Card>
          </div>
        </div>
      )}
    </>
  )
}

export default AddModerator
