//**********************************************//
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { Switch, BrowserRouter as Router, HashRouter } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { authProtectedRoutes, publicRoutes, moderatorRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import { withTranslation } from "react-i18next"

// Import scss
import "./assets/scss/theme.scss"
import { useTranslation } from "react-i18next"

const App = props => {
  const { i18n } = useTranslation(),
    // let roles = sessionStorage.getItem("role")
    [currentRole, setCurrentRole] = useState(
      JSON.parse(sessionStorage.getItem("role"))
    ),
    [setRole, setSetRole] = useState(false)

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  useEffect(() => {
    document.body.dir = i18n.language === "ar" ? "rtl" : "ltr"
  }, [props])

  const getUserRole = async role => {
    setCurrentRole(role)
  }
  console.log("currentRoleApp==>", currentRole)
  return (
    <React.Fragment>
      <HashRouter>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              // layout={Layout}
              component={route.component}
              key={idx}
              handler={getUserRole}
              exact
            />
          ))}

          {currentRole == "admin"
            ? authProtectedRoutes.map((route, idx) => (
                // console.log("authProtected==>"),
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  exact
                />
              ))
            : moderatorRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  exact
                />
              ))}
        </Switch>
      </HashRouter>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(withTranslation()(App))
