import { React, useState, useEffect } from "react"
import moment from "moment"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import PropTypes, { object } from "prop-types"
import "./Chart.css"
import { Button, Card } from "reactstrap"
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts"
import adminDataObject from "../Prizedly-Controller/Controller"
import { result } from "lodash"

function BarChart({ graphData, graphLiftUp }) {
  /************************************ */
  const [selectedCategory, setSelectedCategory] = useState([])
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("")
  const [selectedGeolocation, setSelectedGeolocation] = useState("")
  const [selectedOS, setSelectedOS] = useState("")
  var countriesList = []

  /*********countries List*********** */

  function getCountries(lang = "en") {
    const A = 65
    const Z = 90
    const countryName = new Intl.DisplayNames([lang], { type: "region" })
    const countries = {}
    for (let i = A; i <= Z; ++i) {
      for (let j = A; j <= Z; ++j) {
        let code = String.fromCharCode(i) + String.fromCharCode(j)
        let name = countryName.of(code)
        if (code !== name) {
          countries[code] = name
        }
      }
    }
    return countries
  }

  var formatData = []

  var currentYear = String(new Date().getFullYear())

  var currentMonth = new Date().getMonth() + 1
  if (String(currentMonth).length === 1) {
    currentMonth = "0" + currentMonth
  } else {
    currentMonth = currentMonth
  }

  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD")
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD")

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#6f38c51a",
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: "#000000",
        fontSize: "15px",
        fontFamily: "urbanist",
        fontWeight: 500,
      }
    },
    menuPortal: base => ({ ...base, zIndex: 9999 }),
  }

  /*******timeFrame select****** */
  const timeFrameGroup = [
    {
      label: "Please select the timeframe",
      options: [
        { label: "Last 7 days", value: "week" },
        { label: "Last 15 days", value: "halfMonth" },
        { label: "Last 30 days", value: "fullMonth" },
        { label: "Last 3 months", value: "threeMonths" },
        { label: "Last 12 months", value: "fullYear" },
      ],
    },
  ]
  const changeTimeframeHandler = e => {
    setSelectedTimeFrame(e)
  }

  countriesList = Object.entries(getCountries()).map(c => {
    return { label: "All", value: undefined }, { label: c[1], value: c[0] }
  })

  countriesList.unshift({ label: "All", value: undefined })

  let countryWithCode = Object.entries(getCountries())

  const GeolocationGroup = [
    {
      label: "Please select the country name",
      options: countriesList,
    },
  ]
  // countriesList
  const changeGeoLocationHandler = e => {
    setSelectedGeolocation(e)
  }
  /*******************OS select *************/
  const osGroup = [
    {
      label: "Please select the OS",
      options: [
        { label: "All", value: undefined },
        { label: "Android", value: "android" },
        { label: "IOS", value: "ios" },
      ],
    },
  ]
  const changeOsHandler = e => {
    setSelectedOS(e)
  }
  const filteredData = {
    timeFrame: selectedTimeFrame,
    geolocation: selectedGeolocation,
    OS: selectedOS,
  }
  useEffect(() => {
    graphLiftUp(filteredData)
  }, [selectedTimeFrame, selectedGeolocation, selectedOS])

  var getDaysBetweenDates = function (startDate, endDate) {
    var now = moment(startDate).clone(),
      dates = []

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("MMMDD"))
      now.add(1, "days")
    }
    return dates
  }

  formatData = graphData.map(value => {
    let df = value.start.split("/")
    return selectedTimeFrame.value != "fullYear"
      ? { date: `${df[0]} ${df[1]}`, counts: value.count }
      : { date: `${df[1]} ${df[2].slice(2, 4)}`, counts: value.count }
  })

  return (
    <div>
      <div className="mainDiv">
        <div className="graphFilters">
          <Select
            defaultValue={selectedTimeFrame}
            styles={customStyles}
            onChange={e => {
              changeTimeframeHandler(e)
            }}
            options={timeFrameGroup}
            closeMenuOnSelect={true}
            menuPortalTarget={document.body}
            placeholder={"Last 7 days"}
            onClick={e => {
              e.preventDefault()
            }}
          />
        </div>
        <div className="graphFilters">
          <Select
            defaultValue={selectedGeolocation}
            styles={customStyles}
            onChange={e => {
              changeGeoLocationHandler(e)
            }}
            options={GeolocationGroup}
            closeMenuOnSelect={true}
            menuPortalTarget={document.body}
            placeholder={"Geo: All"}
            onClick={e => {
              e.preventDefault()
            }}
          />
        </div>
        <div className="graphFilters">
          <Select
            defaultValue={selectedOS}
            styles={customStyles}
            onChange={e => {
              changeOsHandler(e)
            }}
            options={osGroup}
            closeMenuOnSelect={true}
            menuPortalTarget={document.body}
            placeholder={"OS: All"}
            onClick={e => {
              e.preventDefault()
            }}
          />
        </div>
      </div>
      <br />
      <br />
      <div>
        <Card style={{ height: "300px", width: "100%", overflowX: "scroll" }}>
          <ResponsiveContainer width="100%" height="95%">
            <AreaChart
              aspect={4 / 1}
              width={500}
              height={400}
              data={formatData}
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 20,
              }}
            >
              <XAxis
                dataKey="date"
                // angle={-45}
                textAnchor="middle"
                tick={{ fontSize: "12px" }}
                // tickLine={{ stroke: "red" }}
              >
                <Label value="Date-Range" offset={0} position="bottom" />
              </XAxis>
              <YAxis
                type="number"
                label={{ fontSize: "10px" }}
                // domain={[0, 10]}
                // tickCount={9}
                // tick={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
              >
                <Label
                  value="Users"
                  offset={20}
                  angle={-90}
                  position="insideLeft"
                />
              </YAxis>
              <Tooltip />
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#9C88FB" stopOpacity={0.3} />
                  <stop offset="95%" stopColor="#9C88FB" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="counts"
                stroke="#B09FFF"
                strokeWidth={3}
                fillOpacity={1}
                fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </Card>
      </div>
    </div>
  )
}
BarChart.propTypes = {
  t: PropTypes.any,
  graphData: PropTypes.any,
  graphLiftUp: PropTypes.func,
}

export default withTranslation()(BarChart)
