import adminDataObject from "pages/Components/Prizedly-Controller/Controller"
import { put, takeEvery, call } from "redux-saga/effects"
// import { loginError, loginSuccess } from "./action"
import { ADMIN_LOGIN, SET_ADMIN_LOGIN } from "./actionType"
import axios from "axios"
import { BASE_URL } from "pages/Components/Prizedly-Controller/Config"

const apiForLogin = payload => {
  return axios({
    method: "POST",
    url: `${BASE_URL}/email/auth`,
    data: payload,
  })
}

function* getProducts({ payload: payload }) {
  try {
    let response = yield call(apiForLogin, payload)
    yield put(loginSuccess(response))
    yield put(loginSuccess(response))
  } catch (error) {
    yield put(loginError(error))
  }
}

function* LoginSaga(data) {
  yield takeEvery(ADMIN_LOGIN, getProducts)
}

export default LoginSaga
