import React, { lazy, Suspense, useEffect, useState, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import "../Common_Style/common_style.css"
import { Button } from "@material-ui/core"
import Toggle from "../Common_Style/Toggle"
import { useHistory } from "react-router-dom"
import { Input } from "reactstrap"
import adminDataObject from "pages/Components/Prizedly-Controller/Controller"
import Swal from "sweetalert2"
import DeleteModal from "../Modal/DeleteModal"
import { useSelector } from "react-redux"
import { CheckBox } from "../Icons/Icons"
import MainFilter from "../Common_Style/Filter/MainFilter"
import "react-tooltip/dist/react-tooltip.css"
import { Tooltip as ReactTooltip } from "react-tooltip"
import {
  PrizedlyPaper,
  PrizedlyTable,
  PrizedlyTableBody,
  PrizedlyTableCell,
  PrizedlyTableContainer,
  PrizedlyTableHead,
  PrizedlyTableRow,
} from "../Common_Style/Table/PrizedlyTable"

const Pagination = lazy(() => import("react-responsive-pagination"))

function createData(NO, ID, Name, Email, status) {
  return {
    NO,
    ID,
    Name,
    Email,
    status,
  }
}

function PartnersManagement() {
  let history = useHistory()
  let mobileWidth = window.screen.width
  // let tableHeight = window.screen.height
  const divElement = useRef()
  const [tableData, setTableData] = useState([])
  const [dataToEdit, setDataToEdit] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [deleteModal, setDeleteModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [checked, setChecked] = useState({ button: true, page: 1 })
  const [checkedId, setCheckedId] = useState([])
  const [isSuspend, setIsSuspend] = useState(false)
  const [toggleId, setToggleId] = useState([])
  const [applyUserFilter, setApplyUserFilter] = useState(false)
  const [filteredData, setFilteredData] = useState(false)
  const [select, setSelect] = useState(true)
  const [tableHeight, setTableHeight] = useState("")
  const [showTable, setShowTable] = useState(false)

  let idArray = []
  var newTableData = []
  let list = []
  const sd = useSelector(state => state.searchedData)

  const searchUserText = useSelector(state => state.searchedData.searched_Text)

  const sortUser = useSelector(state => state.searchedData.sort_Option)

  const filterStatusUser = useSelector(
    state => state.searchedData.statusFilter_Option
  )
  const geoFilterUser = useSelector(
    state => state.searchedData.geoFilter_Option
  )

  useEffect(() => {
    document.title = "Partners-Management"
  }, [])

  // const toggle = () => {
  //   if (modal) {
  //     setModal(false)
  //   } else {
  //     setModal(true)
  //   }
  // }
  useEffect(() => {
    // get the height of the div element
    console.log("The height of the div is: ", divElement.current.offsetHeight)
    setTableHeight(divElement.current.offsetHeight)
  }, [])

  const approvePartnerHandler = () => {
    idArray = checkedId.map(del => {
      return del.id
    })
    const body = {
      userId: idArray,
      status: "active",
    }
    Swal.fire({
      title: "Are you sure?",
      text: "you want to approve this partner",
      icon: "warning",
      buttons: true,
      showConfirmButton: true,
      showCancelButton: true,
    }).then(response => {
      if (response.isConfirmed) {
        adminDataObject.updatePartnerStatus(body, result => {
          if (result.data.status === true) {
            Swal.fire({
              title: "",
              text: `${result.data.message}`,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            })
            dataTable()
            unCheck()
          } else {
            Swal.fire({
              title: "",
              text: `Something went wrong`,
              icon: "warning",
              timer: 2000,
              showConfirmButton: false,
              showConfirmButton: false,
            })
          }
        })
      }
    })
  }

  const dataTable = n => {
    if (n === undefined) {
      n = 1
    }
    adminDataObject.partnersList(
      n,
      searchUserText ? searchUserText : "all",
      applyUserFilter && sortUser ? sortUser : "Z-A",
      applyUserFilter && filterStatusUser ? filterStatusUser : "all",
      applyUserFilter && geoFilterUser ? geoFilterUser : "all",
      result => {
        if (result.data.status === true) {
          setTableData(result.data.data.docs)
          setTotalPages(Math.ceil(result.data.data.totalDocs / 10))
          filterToggle(result.data.data.docs, n)
          setFilteredData(false)
          result.data.data.docs.length > 0
            ? setShowTable(true)
            : setShowTable(false)
        } else {
          setTableData([])
          setTotalPages()
        }
      }
    )
  }
  useEffect(() => {
    dataTable()
  }, [searchUserText])

  useEffect(() => {
    if (applyUserFilter && filteredData) {
      dataTable()
    }
  }, [filteredData])

  function unCheck() {
    setCheckedId([])
  }
  async function check() {
    let list = []
    newTableData.map(async (n, i) => {
      await list.push({ page: currentPage, id: n.ID, _id: n.userId })
    })
    setCheckedId(list)
  }

  var selectedData = (e, itemsToEdit) => {
    let removeData = dataToEdit

    if (e.target.checked) {
      setDataToEdit(ed => [...ed, { itemsToEdit }])
    } else {
      removeData.forEach((v, i) => {
        if (v.itemsToEdit.ID == itemsToEdit.ID) {
          dataToEdit.splice(i, 1)
          setDataToEdit(r => [...r])
        }
      })
    }
  }
  const rows = tableData?.map(val => {
    console.log("val==>", val)
    const { totalPoints, _id, name, email, status } = val
    return newTableData.push(createData(totalPoints, _id, name, email, status))
  })

  const useStyles = makeStyles({
    root: {
      width: "100%",
    },
    container: {
      maxHeight:
        mobileWidth < "767"
          ? tableHeight - 60
          : mobileWidth <= "1000" && mobileWidth > "767"
          ? tableHeight - 50
          : tableHeight - 50,
    },
  })
  const classes = useStyles()

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  const changeStatusOnToggle = (toggleValue, offerId) => {
    const data = {
      userId: offerId,
      status: toggleValue,
    }
    adminDataObject.updateUserStatus(data, result => {
      if (result.data.code === 200) {
        dataTable(currentPage)
      }
    })
  }

  const filterToggle = (array, n) => {
    array.map((t, i) => {
      if (t.status == "active") {
        list.push({ page: n, _id: t._id })
      }
    })
    setToggleId(list)
  }

  const getCheckedStatus = id => {
    const found = checkedId.filter(e => e.id == id && e.page == currentPage)
    if (found.length > 0) {
      return true
    } else {
      return false
    }
  }

  const setCheckedStatus = (id, _id) => {
    const found = checkedId.filter(e => e.id == id && e.page == currentPage)
    let index = 0
    if (found.length > 0) {
      for (let i = 0; i < checkedId.length; i++) {
        if (checkedId[i].page == currentPage && checkedId[i].id == id) {
          index = i
          break
        }
      }
      checkedId.splice(index, 1)
      setCheckedId([...checkedId])
    } else {
      setCheckedId([...checkedId, { page: currentPage, id: id, _id: _id }])
    }
  }

  const getToggleStatus = id => {
    const found = toggleId.filter(e => e._id == id && e.page == currentPage)
    if (found.length > 0) {
      return true
    } else {
      return false
    }
  }

  const setToggledStatus = id => {
    const found = toggleId.filter(e => e._id == id && e.page == currentPage)
    let index = 0
    if (found.length > 0) {
      for (let i = 0; i < toggleId.length; i++) {
        if (toggleId[i].page == currentPage && toggleId[i]._id == id) {
          index = i
          break
        }
      }
      changeStatusOnToggle("inactive", id)
      toggleId.splice(index, 1)
      setToggleId([...toggleId])
    } else {
      changeStatusOnToggle("active", id)
      setToggleId([...toggleId, { page: currentPage, _id: id }])
    }
  }
  const cancelModalHandler = () => {
    setDeleteModal(false)
    setIsSuspend(false)
  }

  const selectUnselectHandler = () => {
    setSelect(!select)
  }

  const applyFiltersHandler = () => {
    setApplyUserFilter(true)
    setFilteredData(true)
  }

  return (
    <div className={`main-content${classAlign}`}>
      <div
        className="page-content"
        style={{ paddingBottom: "10px", height: "100vh" }}
      >
        <div className="content-container">
          <MainFilter apply={applyFiltersHandler} />
          <br />
          {/* <DeleteModal
            suspend={isSuspend}
            show={deleteModal}
            onDeleteClick={isSuspend ? handleSuspendEvent : handleDeleteEvent}
            onCloseClick={cancelModalHandler}
          /> */}
          <ReactTooltip
            style={{ zIndex: "99" }}
            anchorId="edit-user"
            place="bottom"
            type="dark"
            effect="float"
            content="Edit"
          />
          <ReactTooltip
            style={{ zIndex: "99" }}
            anchorId="delete-user"
            place="bottom"
            type="dark"
            effect="float"
            content="Delete"
          />
          <ReactTooltip
            style={{ zIndex: "99" }}
            anchorId="suspend-user"
            place="bottom"
            type="dark"
            effect="float"
            content="Suspend"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <div
              className="addButton"
              // style={{ width: "10vw" }}
              onClick={() => {
                setChecked({ button: !checked.button })
                if (checked.button) {
                  check()
                } else {
                  unCheck()
                }
              }}
            >
              <Button
                style={{ color: "white" }}
                onClick={selectUnselectHandler}
              >
                {
                  <div className="addBtn">
                    {select ? "Select all" : "Unselect All"}
                    {select ? <CheckBox /> : ""}
                  </div>
                }
              </Button>
            </div>

            <div className="addButton" onClick={approvePartnerHandler}>
              <Button style={{ color: "white" }}>
                {<div className="addBtn">{"Approve"}</div>}
              </Button>
            </div>
          </div>
          <div style={{ height: "100%" }} ref={divElement}>
            <PrizedlyPaper className={classes.root}>
              <PrizedlyTableContainer
                component={PrizedlyPaper}
                className={classes.container}
              >
                <PrizedlyTable
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  stickyHeader
                >
                  <PrizedlyTableHead className="tableHeader">
                    <PrizedlyTableRow>
                      <PrizedlyTableCell className="tableHeaderCell"></PrizedlyTableCell>
                      <PrizedlyTableCell className="tableHeaderCell">
                        S.No.
                      </PrizedlyTableCell>
                      <PrizedlyTableCell
                        align="center"
                        className="tableHeaderCell"
                      >
                        Name
                      </PrizedlyTableCell>
                      <PrizedlyTableCell
                        align="center"
                        className="tableHeaderCell"
                      >
                        Email
                      </PrizedlyTableCell>
                      <PrizedlyTableCell
                        align="center"
                        className="tableHeaderCell"
                      >
                        Status
                      </PrizedlyTableCell>
                    </PrizedlyTableRow>
                  </PrizedlyTableHead>
                  <PrizedlyTableBody>
                    {showTable ? (
                      newTableData.map((row, i) =>
                        (() => {
                          if (i % 2 != 0) {
                            console.log("rowIf==>", row)
                            return (
                              <PrizedlyTableRow
                                key={row.ID}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                style={{ backgroundColor: "#f8fafb" }}
                              >
                                <PrizedlyTableCell align="center">
                                  {
                                    <Input
                                      type="checkbox"
                                      name="tableInput"
                                      checked={getCheckedStatus(row.ID)}
                                      id={row.ID.toString()}
                                      onChange={() => {
                                        if (checkedId.length == 0) {
                                          setCheckedId([
                                            ...checkedId,
                                            {
                                              page: currentPage,
                                              id: row.ID,
                                              _id: row.userId,
                                            },
                                          ])
                                        } else {
                                          setCheckedStatus(row.ID, row.userId)
                                        }
                                      }}
                                    />
                                  }
                                </PrizedlyTableCell>
                                <PrizedlyTableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                  className="rowCell"
                                >
                                  {(currentPage - 1) * 10 + (i + 1)}
                                </PrizedlyTableCell>
                                <PrizedlyTableCell
                                  align="center"
                                  className="rowCell"
                                >
                                  {row.Name}
                                </PrizedlyTableCell>
                                <PrizedlyTableCell
                                  align="center"
                                  className="rowCell"
                                >
                                  {row.Email}
                                </PrizedlyTableCell>
                                <PrizedlyTableCell
                                  align="center"
                                  className="rowCell"
                                >
                                  {row.status.charAt(0).toUpperCase() +
                                    row.status.slice(1)}
                                </PrizedlyTableCell>
                              </PrizedlyTableRow>
                            )
                          } else {
                            console.log("rowElse==>", row)
                            return (
                              <PrizedlyTableRow
                                key={row.ID}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                style={{ backgroundColor: "#f8fafb" }}
                              >
                                <PrizedlyTableCell align="center">
                                  {
                                    <Input
                                      type="checkbox"
                                      name="tableInput"
                                      checked={getCheckedStatus(row.ID)}
                                      id={row.ID.toString()}
                                      onChange={() => {
                                        if (checkedId.length == 0) {
                                          setCheckedId([
                                            ...checkedId,
                                            {
                                              page: currentPage,
                                              id: row.ID,
                                              _id: row.userId,
                                            },
                                          ])
                                        } else {
                                          setCheckedStatus(row.ID, row.userId)
                                        }
                                      }}
                                    />
                                  }
                                </PrizedlyTableCell>
                                <PrizedlyTableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                  className="rowCell"
                                >
                                  {(currentPage - 1) * 10 + (i + 1)}
                                </PrizedlyTableCell>
                                <PrizedlyTableCell
                                  align="center"
                                  className="rowCell"
                                >
                                  {row.Name}
                                </PrizedlyTableCell>
                                <PrizedlyTableCell
                                  align="center"
                                  className="rowCell"
                                >
                                  {row.Email}
                                </PrizedlyTableCell>
                                <PrizedlyTableCell
                                  align="center"
                                  className="rowCell"
                                >
                                  {row.status.charAt(0).toUpperCase() +
                                    row.status.slice(1)}
                                </PrizedlyTableCell>
                              </PrizedlyTableRow>
                            )
                          }
                        })()
                      )
                    ) : (
                      <div className="noTableData"> {"No data found !"}</div>
                    )}
                  </PrizedlyTableBody>
                </PrizedlyTable>
              </PrizedlyTableContainer>
            </PrizedlyPaper>
            <div style={{ marginTop: 10 }}>
              <Suspense fallback={<div>Loading...</div>}>
                <Pagination
                  current={currentPage}
                  maxWidth={5}
                  total={totalPages}
                  onPageChange={n => {
                    setCurrentPage(n)
                    dataTable(n)
                  }}
                />
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartnersManagement
