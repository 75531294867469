import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./Searchbar.css"
import { searchedString } from "../../../../../store/actions"
import { useDispatch } from "react-redux"
import { debounce } from "lodash"
function Searchbar({ compClass }) {
  const dispatch = useDispatch()
  const [query, setQuery] = useState("")
  const [data, setData] = useState([])

  const handleText = debounce(text => {
    dispatch(searchedString(text))
  }, 1000)

  return (
    <div>
      <input
        className="searchWeb"
        placeholder="Search:All"
        onChange={e => {
          handleText(e.target.value.toLowerCase())
        }}
      />
    </div>
  )
}
Searchbar.propTypes = {
  compClass: PropTypes.any,
}

export default Searchbar
