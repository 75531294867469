import React, { useState, useEffect, useRef } from "react"
import Searchbar from "../searchbar/Searchbar"
import Sort from "../sort/Sort"
import GeoFilter from "./GeoFilter"
import StatusFilter from "./StatusFilter"
import "./MainFilter.css"
import { Button } from "reactstrap"
import PropTypes from "prop-types"
import "../../Common_Style/common_style.css"
import { useDispatch } from "react-redux"

function MainFilter({ apply }) {
  const [heading, setHeading] = useState("")
  const [elementHeight, setElementHeight] = useState("")
  const divElement = useRef()
  const loc = window.location.hash
  const selectedTab = loc.split("#")
  let editTab = selectedTab[1].split("/")

  const pageRoute = {
    "/user_management": "User Management",
    "/user_management/add_user": "User Management",
    "/discoveries_management": "Discoveries Management",
    "/dashboard": "Dashboard",
    "/payment_management": "Payment Management",
    "/notification_system": "Notification System",
    "/settings": "Settings",
  }
  const arr = Object.keys(pageRoute)

  useEffect(() => {
    arr.forEach((v, i) => {
      if (selectedTab.includes(v)) setHeading(pageRoute[v])
      else if (`/${editTab[1]}`.includes(v)) setHeading(pageRoute[v])
    }),
      selectedTab[1] === "/user_management" ||
        selectedTab[1] === "/discoveries_management" ||
        selectedTab[1] === "/payment_management"
  }, [selectedTab])

  const applyButtonHandler = () => {
    apply()
  }
  return (
    <div ref={divElement} className="mainContainer">
      <div className="filterContainer">
        <div className="optionsContainer">
          <Sort />
        </div>
        {selectedTab[1] !== "/admin_moderator_management" && (
          <div className="optionsContainer">
            <StatusFilter
              filterGroup={
                selectedTab[1] === "/user_management"
                  ? "userFilterGroup"
                  : selectedTab[1] === "/discoveries_management"
                  ? "offerFilterGroup"
                  : selectedTab[1] === "/partners_management"
                  ? "partnersFilterGroup"
                  : selectedTab[1] === "/moderator_management"
                  ? "moderatorsFilterGroup"
                  : "paymentFilterGroup"
              }
            />
            {console.log("selectedTab==>", selectedTab)}
          </div>
        )}
        {selectedTab[1] === "/payment_management" ||
        selectedTab[1] === "/partners_management" ? (
          ""
        ) : (
          <div className="optionsContainer">
            <GeoFilter />
          </div>
        )}
      </div>
      <div>
        <Button className="applyButton" onClick={applyButtonHandler}>
          Apply
        </Button>
      </div>
    </div>
  )
}
MainFilter.propTypes = {
  apply: PropTypes.func,
}

export default MainFilter
