import { LOGIN_SUCCESS, LOGIN_ERROR } from "./actionType"

export const authAdmin = (data = {}, action) => {
  //   return "abc";
  switch (action.type) {
    case LOGIN_SUCCESS:
      return action.payload
    case LOGIN_ERROR:
      return action.payload
    default:
      return data
  }
}
