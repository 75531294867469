import React, { lazy, Suspense } from "react"
import PropTypes from "prop-types"

const MyTable = lazy(() =>
  import("@mui/material").then(mod => ({
    default: mod.Table,
  }))
)

export const PrizedlyTable = props => (
  <Suspense fallback={<div className={props.className}>{props.children}</div>}>
    <MyTable {...props} />
  </Suspense>
)

const MyTableBody = lazy(() =>
  import("@mui/material").then(mod => ({
    default: mod.TableBody,
  }))
)

export const PrizedlyTableBody = props => (
  <Suspense fallback={<div className={props.className}>{props.children}</div>}>
    <MyTableBody {...props} />
  </Suspense>
)

const MyTableCell = lazy(() =>
  import("@mui/material").then(mod => ({
    default: mod.TableCell,
  }))
)

export const PrizedlyTableCell = props => (
  <Suspense fallback={<div className={props.className}>{props.children}</div>}>
    <MyTableCell {...props} />
  </Suspense>
)

const MyTableContainer = lazy(() =>
  import("@mui/material").then(mod => ({
    default: mod.TableContainer,
  }))
)

export const PrizedlyTableContainer = props => (
  <Suspense fallback={<div className={props.className}>{props.children}</div>}>
    <MyTableContainer {...props} />
  </Suspense>
)

const MyTableHead = lazy(() =>
  import("@mui/material").then(mod => ({
    default: mod.TableHead,
  }))
)

export const PrizedlyTableHead = props => (
  <Suspense fallback={<div className={props.className}>{props.children}</div>}>
    <MyTableHead {...props} />
  </Suspense>
)

const MyTableRow = lazy(() =>
  import("@mui/material").then(mod => ({
    default: mod.TableRow,
  }))
)

export const PrizedlyTableRow = props => (
  <Suspense fallback={<div className={props.className}>{props.children}</div>}>
    <MyTableRow {...props} />
  </Suspense>
)

const MyPaper = lazy(() =>
  import("@mui/material").then(mod => ({
    default: mod.Paper,
  }))
)

export const PrizedlyPaper = props => (
  <Suspense fallback={<div className={props.className}>{props.children}</div>}>
    <MyPaper {...props} />
  </Suspense>
)

PrizedlyTable.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
}
PrizedlyTableBody.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
}
PrizedlyTableCell.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
}
PrizedlyTableContainer.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
}
PrizedlyTableHead.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
}
PrizedlyTableRow.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
}
PrizedlyPaper.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
}
