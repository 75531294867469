import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { BiLogOut } from "react-icons/bi"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar.png"

const ProfileMenu = props => {
  const history = useHistory()
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")

  const logoutHandler = () => {
    window.sessionStorage.clear()
    history.push("/")
  }

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
          style={{
            display: "flex",
            flexDirection: "row",
            marginRight: "10px",
            justifyContent: "center",
            alignItems: "center",
            padding: "0.25em",
          }}
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          {/* <span
            className="d-none d-xl-inline-block ms-2 me-1"
            style={{
              color: "white",
              maxWidth: "100px",
              fontSize: "15px",
            }}
          >
            {username}
          </span> */}
          {/* <i
            className="mdi mdi-chevron-down d-none d-xl-inline-block"
            style={{ color: "white" }}
          /> */}
        </DropdownToggle>
        <DropdownMenu className={`dropdown-menu-end_${classAlign}`}>
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {props.t("Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1"/>
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1"/>
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1"/>
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider " />
          <div
            className="dropdown-item"
            style={{ cursor: "pointer" }}
            onClick={logoutHandler}
          >
            <BiLogOut style={{ fontSize: "20px", marginRight: "5px" }} />
            <span>{props.t("Logout")}</span>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(ProfileMenu))
