import {
  searched_Text,
  sort_Option,
  statusFilter_Option,
  geoFilter_Option,
  div_Height,
} from "./actionTypes"

export const searchedString = data => {
  console.log("actionSearch==>", data)
  return {
    type: searched_Text,
    payload: data,
  }
}

export const sortString = data => {
  console.log("actionSort==>", data)
  return {
    type: sort_Option,
    payload: data,
  }
}

export const filterStatusString = data => {
  console.log("actionFilter==>", data)
  return {
    type: statusFilter_Option,
    payload: data,
  }
}

export const filterGeoString = data => {
  console.log("actionGeoFilter==>", data)
  return {
    type: geoFilter_Option,
    payload: data,
  }
}
// export const DivHeight = data => {
//   console.log("actionDivHeight==>", data)
//   return { type: div_Height, payload: data }
// }
