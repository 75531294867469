import React from "react"
import "./unauthorized.style.css"
import image_403 from "../../../Assets/Images/error.png"
import { useHistory } from "react-router"

function Unauthorized() {
  const history = useHistory()
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      <div className="page-content">
        <div className="page-container">
          <img src={image_403} alt="403" width="100%" height="auto" />
          <div className="back-button-container">
            <br />
            <button className="back-button" onClick={history.goBack}>
              Go back
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Unauthorized
