import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import Select from "react-select"
import { sortString } from "store/actions"
import { CoinIcon, SortIcon } from "../../Icons/Icons"

function Sort({ compClass }) {
  const dispatch = useDispatch()
  const [query, setQuery] = useState("")
  const [defaultSort, setDefaultSort] = useState("")

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#6f38c51a",
      borderColor: "#6f38c51a",
      border: "none",
      borderRadius: "5px",
      minHeight: "35px",
      height: "35px",
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: "#000000",
        fontFamily: "poppins",
        fontSize: "16px",
        fontWeight: "400",
      }
    },
    menuPortal: base => ({
      ...base,
      zIndex: 9999,
      color: "#000000",
      fontFamily: "poppins",
      fontSize: "16px",
      fontWeight: "400",
    }),
    MenuList: base => ({
      ...base,
      // zIndex: 9999,
      color: "#000000",
      fontFamily: "poppins",
      fontSize: "16px",
      fontWeight: "400",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),
    indicatorSeparator: base => ({
      ...base,
      display: "none",
    }),
  }

  const sortGroup = [
    {
      label: "",
      options: [
        { label: "Newest-Oldest", value: "Z-A" },
        { label: "Oldest-Newest", value: "A-Z" },
      ],
    },
  ]

  return (
    <div
      // style={{ height: "10px" }}
      className={`${compClass}`}
    >
      <Select
        defaultValue={defaultSort}
        styles={customStyles}
        onChange={e => {
          setQuery(e.value)
          dispatch(sortString(e.value))
        }}
        options={sortGroup}
        closeMenuOnSelect={true}
        menuPortalTarget={document.body}
        placeholder={
          <>
            {"Sort: All"} <SortIcon />
          </>
        }
        onClick={e => {
          e.preventDefault()
        }}
      />
    </div>
  )
}
Sort.propTypes = {
  compClass: PropTypes.any,
}

export default Sort
