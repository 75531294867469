import {
  searched_Text,
  sort_Option,
  statusFilter_Option,
  geoFilter_Option,
  div_Height,
} from "./actionTypes"

const iniState = {
  searched_Text: "",
  sort_Option: "",
  statusFilter_Option: "",
  geoFilter_Option: "",
  divElementHeight: "",
}
export const searchedData = (state = iniState, actions) => {
  switch (actions.type) {
    case searched_Text:
      return { ...state, searched_Text: actions.payload }

    case sort_Option:
      return { ...state, sort_Option: actions.payload }

    case statusFilter_Option:
      return { ...state, statusFilter_Option: actions.payload }

    case geoFilter_Option:
      return { ...state, geoFilter_Option: actions.payload }
    // case div_Height:
    //   return { ...state, divElementHeight: actions.payload }
    default:
      return ""
  }
}
