import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import "./Toggle.css"

const Toggle = ({
  isToggled,
  isToggledFirst,
  isToggledSecond,
  onToggle,
  name,
}) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (isToggledFirst || isToggledSecond || isToggled) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [isToggledFirst || isToggledSecond || isToggled])
  return (
    <label className="switch">
      <input
        type="checkbox"
        name={name}
        checked={visible}
        className="switch-input"
        onChange={() => onToggle()}
      />
      <span className="slider" />
    </label>
  )
}
Toggle.propTypes = {
  rounded: PropTypes.any,
  isToggled: PropTypes.any,
  isToggledFirst: PropTypes.any,
  isToggledSecond: PropTypes.any,
  onToggle: PropTypes.func,
  name: PropTypes.any,
}

export default Toggle
