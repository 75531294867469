import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { FaUserFriends, FaHandshake } from "react-icons/fa"
import { MdPayment } from "react-icons/md"
import { AiOutlineSetting } from "react-icons/ai"
import { TbLayoutDashboard, TbDiscount2 } from "react-icons/tb"
import { HiUserGroup } from "react-icons/hi2"
import "../../App.css"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

// import { color } from "@mui/system"

const SidebarContent = props => {
  const ref = useRef()
  const [currentRole, setCurrentRole] = useState("")
  const [color, setColor] = useState({
    a: {},
    b: null,
    c: null,
    d: null,
  })
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously

  const loc = window.location.hash
  const selectedTab = loc.slice(0, -5)
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }
  useEffect(() => {
    getValueFromLocalStorage("role")
  }, [])

  async function getValueFromLocalStorage(key) {
    const value = await JSON.parse(sessionStorage.getItem(key))
    return setCurrentRole(value)
  }
  console.log("currentRole==>", currentRole)
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" style={{ position: "fixed", top: "70px" }}>
          <ul
            className={`metismenu list-unstyled ${classAlign}`}
            id="side-menu"
          >
            <br />
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            {currentRole == "admin" && (
              <li
                className={
                  props.location.pathname === "/dashboard" ? "activeTab" : ""
                }
              >
                <Link
                  to="/dashboard"
                  className="sidebarListItem"
                  style={{ textDecoration: "none" }}
                >
                  <TbLayoutDashboard className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">Dashboard</span>
                </Link>
              </li>
            )}

            {currentRole == "admin" && (
              <li
                className={
                  props.location.pathname === "/user_management" ||
                  props.location.pathname === "/add_user"
                    ? "activeTab"
                    : ""
                }
              >
                <Link
                  to="/user_management"
                  className="sidebarListItem"
                  style={{ textDecoration: "none" }}
                >
                  <FaUserFriends className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">User management</span>
                </Link>
              </li>
            )}
            {currentRole == "admin" && (
              <li
                className={
                  props.location.pathname === "/discoveries_management"
                    ? "activeTab"
                    : ""
                }
              >
                <Link
                  to="/discoveries_management"
                  className="sidebarListItem"
                  style={{ textDecoration: "none" }}
                >
                  <TbDiscount2 className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">
                    Discoveries Management
                  </span>
                </Link>
              </li>
            )}
            {currentRole == "admin" && (
              <li
                className={
                  props.location.pathname === "/payment_management"
                    ? "activeTab"
                    : ""
                }
              >
                <Link
                  to="/payment_management"
                  className="sidebarListItem"
                  style={{ textDecoration: "none" }}
                >
                  <MdPayment className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">Payment Management</span>
                </Link>
              </li>
            )}
            {currentRole == "admin" && (
              <li
                className={
                  props.location.pathname === "/partners_management"
                    ? "activeTab"
                    : ""
                }
              >
                <Link
                  to="/partners_management"
                  className="sidebarListItem"
                  style={{ textDecoration: "none" }}
                >
                  <FaHandshake className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">Partners</span>
                </Link>
              </li>
            )}
            {currentRole == "admin" && (
              <li
                className={
                  props.location.pathname === "/admin_moderator_management"
                    ? "activeTab"
                    : ""
                }
              >
                <Link
                  to="/admin_moderator_management"
                  className="sidebarListItem"
                  style={{ textDecoration: "none" }}
                >
                  <HiUserGroup className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">Moderators Admin</span>
                </Link>
              </li>
            )}
            {currentRole === "moderator" && (
              <li
                className={
                  props.location.pathname === "/moderator_management"
                    ? "activeTab"
                    : ""
                }
              >
                <Link
                  to="moderator_management"
                  className="sidebarListItem"
                  style={{ textDecoration: "none" }}
                >
                  <HiUserGroup className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">Moderators</span>
                </Link>
              </li>
            )}
            {currentRole == "admin" && (
              <li
                className={
                  props.location.pathname === "/settings" ? "activeTab" : ""
                }
              >
                <Link
                  to="/settings"
                  className="sidebarListItem"
                  style={{ textDecoration: "none" }}
                >
                  <AiOutlineSetting className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">Settings</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
