import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import Select from "react-select"
import { filterStatusString } from "store/actions"
import { FilterIcon } from "../../Icons/Icons"
import { TbChevronsDownLeft } from "react-icons/tb"

function StatusFilter({ compClass, filterGroup }) {
  const dispatch = useDispatch()
  const [query, setQuery] = useState("")
  const [filterType, setFilterType] = useState("")
  const [defaultFilter, setDefaultFilter] = useState("")
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#6f38c51a",
      borderColor: "#6f38c51a",
      border: "none",
      borderRadius: "5px",
      minHeight: "35px",
      height: "35px",
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: "#000000",
        fontFamily: "poppins",
        fontSize: "16px",
        fontWeight: "400",
      }
    },
    menuPortal: base => ({
      ...base,
      zIndex: 9999,
      color: "#000000",
      fontFamily: "poppins",
      fontSize: "16px",
      fontWeight: "400",
    }),
    MenuList: base => ({
      ...base,
      // zIndex: 9999,
      color: "#000000",
      fontFamily: "poppins",
      fontSize: "16px",
      fontWeight: "400",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),
    indicatorSeparator: base => ({
      ...base,
      display: "none",
    }),
  }

  const obj = {
    userFilterGroup: [
      {
        label: "",
        options: [
          { label: "All", value: "all" },
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" },
          { label: "Suspended", value: "suspended" },
        ],
      },
    ],
    offerFilterGroup: [
      {
        label: "",
        options: [
          { label: "All", value: "all" },
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" },
        ],
      },
    ],
    paymentFilterGroup: [
      {
        label: "",
        options: [
          { label: "All", value: "all" },
          { label: "Completed", value: "success" },
          { label: "Failed", value: "failed" },
          { label: "In Progress", value: "pending" },
        ],
      },
    ],
    partnersFilterGroup: [
      {
        label: "",
        options: [
          { label: "All", value: "all" },
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" },
        ],
      },
    ],
    moderatorsFilterGroup: [
      {
        label: "",
        options: [
          { label: "All", value: "all" },
          { label: "Under Approval", value: "under approval" },
          { label: "Running", value: "running" },
          { label: "Paused", value: "paused" },
          { label: "Rejected-Draft", value: "rejected-draft" },
        ],
      },
    ],
  }

  return (
    <>
      <div className={`${compClass}`}>
        <Select
          defaultValue={defaultFilter}
          styles={customStyles}
          onChange={e => {
            setQuery(e.value)
            dispatch(filterStatusString(e.value))
          }}
          options={obj[filterGroup]}
          closeMenuOnSelect={true}
          menuPortalTarget={document.body}
          placeholder={
            <>
              {"Status: All"} <FilterIcon />
            </>
          }
          onClick={e => {
            e.preventDefault()
          }}
        />
      </div>
    </>
  )
}
StatusFilter.propTypes = {
  compClass: PropTypes.any,
  filterGroup: PropTypes.any,
}

export default StatusFilter
