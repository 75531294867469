import React, { useState } from "react"
import { FilterIcon } from "../../Icons/Icons"
import Select from "react-select"
import PropTypes from "prop-types"
import { filterGeoString } from "store/actions"
import { useDispatch } from "react-redux"

function GeoFilter({ compClass }) {
  const dispatch = useDispatch()
  const [query, setQuery] = useState("")
  const [filterType, setFilterType] = useState("")
  const [defaultFilter, setDefaultFilter] = useState("")
  var countriesList = []

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#6f38c51a",
      borderColor: "#6f38c51a",
      border: "none",
      borderRadius: "5px",
      minHeight: "35px",
      height: "35px",
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: "#000000",
        fontFamily: "poppins",
        fontSize: "16px",
        fontWeight: "400",
      }
    },
    menuPortal: base => ({
      ...base,
      zIndex: 9999,
      color: "#000000",
      fontFamily: "poppins",
      fontSize: "16px",
      fontWeight: "400",
    }),
    MenuList: base => ({
      ...base,
      // zIndex: 9999,
      color: "#000000",
      fontFamily: "poppins",
      fontSize: "16px",
      fontWeight: "400",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),
    indicatorSeparator: base => ({
      ...base,
      display: "none",
    }),
  }
  function getCountries(lang = "en") {
    const A = 65
    const Z = 90
    const countryName = new Intl.DisplayNames([lang], { type: "region" })
    const countries = {}
    for (let i = A; i <= Z; ++i) {
      for (let j = A; j <= Z; ++j) {
        let code = String.fromCharCode(i) + String.fromCharCode(j)
        let name = countryName.of(code)
        if (code !== name) {
          countries[code] = name
        }
      }
    }
    return countries
  }

  countriesList = Object.entries(getCountries()).map(c => {
    return { label: "All", value: "all" }, { label: c[1], value: c[0] }
  })

  countriesList.unshift({ label: "All", value: "all" })

  let countryWithCode = Object.entries(getCountries())

  const GeolocationGroup = [
    {
      label: "Please select the country name",
      options: countriesList,
    },
  ]
  return (
    <div className={`${compClass}`}>
      <Select
        defaultValue={defaultFilter}
        styles={customStyles}
        onChange={e => {
          setQuery(e.value)
          dispatch(filterGeoString(e.value))
        }}
        options={GeolocationGroup}
        // options={userFilterGroup}
        closeMenuOnSelect={true}
        menuPortalTarget={document.body}
        placeholder={
          <>
            {"Geo: All"} <FilterIcon />
          </>
        }
        onClick={e => {
          e.preventDefault()
        }}
      />
    </div>
  )
}
GeoFilter.propTypes = {
  compClass: PropTypes.any,
  filterGroup: PropTypes.any,
}

export default GeoFilter
