import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"
import sidebar_logo from "../../pages/Components/Assets/Images/Prizedly_sidebar_ logo.png"
import dashLogo from "../../pages/Components/Assets/Images/logo_icon.png"

const Sidebar = props => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box" style={{ height: "80px" }}>
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={dashLogo} alt="" height="168" width="168" />
            </span>
            <span className="logo-lg"></span>
          </Link>

          <div>
            <span float="left" style={{ marginLeft: "18.75px" }}>
              <img src={sidebar_logo} alt="" height="65" width="200" />
            </span>
          </div>
        </div>
        <br />
        <br />
        <div data-simplebar className="h-150">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
