const languages = {
  // en: {
  //   label: "English",
  //   flag: usFlag,
  // },
  // ar: {
  //   label: "العربية",
  //   flag: SA,
  // },
}

export default languages
