import "../../App.css"
import "../Components/Pages/Common/Home.css"
import { React, useState, useEffect } from "react"
import FeaturedInfo from "pages/Components/FeaturedInfo/FeaturedInfo"
import { Route, Redirect, useHistory } from "react-router-dom"
import { FaEdit, FaEye } from "react-icons/fa"
import { MdDeleteOutline } from "react-icons/md"
import MaterialTable from "material-table"
import { withTranslation } from "react-i18next"
import PropTypes, { object } from "prop-types"
import adminDataObject from "pages/Components/Prizedly-Controller/Controller"
import Swal from "sweetalert2"
import BarChart from "pages/Components/Chart/BarChart"

function Dashboard(props) {
  const history = useHistory()
  const [cardData, setCardData] = useState([])
  const [graphData, setGraphData] = useState([])
  const [filterOptions, setFilterOptions] = useState([
    { label: "", value: "week" },
    { label: "", value: "" },
    { label: "", value: "" },
  ])
  var filtersValueArray = []

  useEffect(() => {
    document.title = "Dashboard"
  }, [])

  const getDashboardData = e => {
    adminDataObject.dashboardData(
      !e[0].value ? "week" : e[0].value,
      !e[1].value ? "" : e[1].value,
      !e[2].value ? "" : e[2].value,
      result => {
        if (result.data.status === true) {
          setCardData(result.data.data.cardData)
          setGraphData(result.data.data.graphData)
        } else {
          Swal.fire("Something went wrong!")
        }
      }
    )
  }
  useEffect(() => {}, [filterOptions])

  const graphFilters = filterData => {
    setFilterOptions(Object.values(filterData))
    getDashboardData(Object.values(filterData))
  }

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  return (
    <div className={`main-content${classAlign}`}>
      <div className="page-content">
        <div className="home">
          <div className="container2">
            <BarChart graphData={graphData} graphLiftUp={graphFilters} />
            <br />
            <FeaturedInfo data={cardData} />
          </div>
        </div>
      </div>
    </div>
  )
}
Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
