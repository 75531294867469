import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

//searchbar
import { searchedData } from "./search/reducer"

// Login
import { authAdmin } from "./auth/Prizedly_Login/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  searchedData,
  authAdmin,
})

export default rootReducer
