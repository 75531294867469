import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import { Link } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import sidebar_logo from "../../pages/Components/Assets/Images/Prizedly_logo.png"
import { FaBars } from "react-icons/fa"
//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import { PosAnimation } from "leaflet"
import Searchbar from "pages/Components/Pages/Common_Style/searchbar/Searchbar"
import { MagnifyingGlass } from "pages/Components/Pages/Icons/Icons"
import Sort from "pages/Components/Pages/Common_Style/sort/Sort"
// import Filter from "pages/Components/Pages/Common_Style/Filter/Filter"
import GeoFilter from "pages/Components/Pages/Common_Style/Filter/GeoFilter"

const Header = props => {
  const loc = window.location.hash
  const selectedTab = loc.split("#")
  let editTab = selectedTab[1].split("/")

  const [search, setsearch] = useState(false)
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const [heading, setHeading] = useState("")
  const [showHeaderOptions, setShowHeaderOptions] = useState(false)
  const [mobileWidth, setMobileWidth] = useState(false)

  const pageRoute = {
    "/user_management": "User Management",
    "/user_management/add_user": "User Management",
    "/discoveries_management": "Discoveries Management",
    "/dashboard": "Dashboard",
    "/payment_management": "Payment Management",
    "/partners_management": "Partner Management",
    "/admin_moderator_management": "Admin Moderator",
    "/moderator_management": "Moderator Management",
    "/settings": "Settings",
  }
  const headerWithSortAndFilter = [
    { path: "/user_management" },
    { path: "/discoveries_management" },
    { path: "/payment_management" },
    { path: "/partners_management" },
    { path: "/admin_moderator_management" },
    { path: "/moderator_management" },
  ]
  const arr = Object.keys(pageRoute)

  useEffect(() => {
    arr.forEach((v, i) => {
      if (selectedTab.includes(v)) setHeading(pageRoute[v])
      else if (`/${editTab[1]}`.includes(v)) setHeading(pageRoute[v])
      // else if (selectedTab.includes()) setShowHeaderOptions(true)
    }),
      (selectedTab[1] === "/user_management" ||
        selectedTab[1] === "/discoveries_management" ||
        selectedTab[1] === "/partners_management" ||
        selectedTab[1] === "/admin_moderator_management" ||
        selectedTab[1] === "/moderator_management" ||
        selectedTab[1] === "/payment_management") &&
        setShowHeaderOptions(true)
  }, [selectedTab])

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
      body.classList.toggle("vertical-collpsed")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  window.addEventListener("resize", function (event) {
    var body = document.body
    if (document.body.clientWidth <= 998) {
      setMobileWidth(true)
      // body.classList.add("sidebar-enable")
      body.classList.add("vertical-collpsed")
    } else {
      setMobileWidth(false)
      body.classList.remove("sidebar-enable")
      body.classList.remove("vertical-collpsed")
    }
  })
  useEffect(() => {
    var body = document.body
    if (window.screen.width <= 998) {
      setMobileWidth(true)
      body.classList.toggle("sidebar-enable")
      body.classList.toggle("vertical-collpsed")
    } else {
      setMobileWidth(false)
    }
  }, [window.screen.width])
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <React.Fragment>
      <header
        id="page-topbar"
        className={`${classAlign}`}
        style={{ height: "80px", paddingTop: "3px" }}
      >
        <div className="navbar-header" style={{ justifyContent: "flex-start" }}>
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <div className="logo logo-dark">
                <span className="logo-sm">
                  <img src={sidebar_logo} alt="" height="70px" width="70px" />
                </span>
              </div>

              <div className="logo logo-light">
                <span className="logo-sm">
                  <img
                    src={sidebar_logo}
                    alt=""
                    height="50"
                    borderRadius="50%"
                  />
                </span>
              </div>
            </div>

            <button
              type="button"
              onClick={() => {
                window.screen.width <= 998 ? tToggle() : ""
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
              style={{ color: "#3C3C43" }}
            >
              <FaBars />
              {/* <i className="fa fa-fw fa-bars" /> */}
            </button>
          </div>
          {/* {mobileWidth ? (
            <div
              className="d-flex"
              style={{ flexGrow: "1", justifyContent: "space-between" }}
            >
              <div
                className={
                  showHeaderOptions ? "" : "headerHalfContainerHeaderOption"
                }
              >
                <div className={showHeaderOptions ? "headerHalfContainer" : ""}>
                  <span className="pageHeading">{heading}</span>
                  {showHeaderOptions && <Searchbar compClass={"search"} />}
                </div>
              </div>
              <div className="">
                <ProfileMenu />
              </div>
            </div>
          ) : ( */}
          <div
            className="d-flex"
            style={{ flexGrow: "1", justifyContent: "space-between" }}
          >
            <span className="pageHeading">{heading}</span>
            {showHeaderOptions ? (
              <div className="searchAndFilter-container">
                <Searchbar compClass={"searchWeb"} />
                <ProfileMenu />
              </div>
            ) : (
              <div className="searchAndFilter-container">
                <ProfileMenu />
              </div>
            )}
          </div>
          {/* )} */}
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}
Header.propTypes = {
  location: PropTypes.object,
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
