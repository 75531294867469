import axios from "axios"
// import { BASE_URL } from "./Config"
const BASE_URL = process.env.REACT_APP_BASE_URL

const adminDataObject = {
  adminLogin: async (data, callback) => {
    axios({
      method: "POST",
      url: BASE_URL + "/email/auth",
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {
        return callback(err.response)
      })
  },
  moderatorLogin: async (data, callback) => {
    axios({
      method: "POST",
      url: "https://api.staging.prized.ly/moderator/email/auth",
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {
        return callback(err.response)
      })
  },

  adminForgotPassword: async (data, callback) => {
    axios({
      method: "POST",
      url: BASE_URL + "/forgot-password",
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {})
  },

  userList: async (n, search, sort, statusFilter, geoFilter, callback) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/users/all?page=${n}&limit=${10}&search=${search}&sort=${sort}&status=${statusFilter}&geoLocation=${geoFilter}`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
    })
      .then(response => {
        return response
      })
      .then(res => {
        return callback(res)
      })
      .catch(err => {})
  },

  deleteUser: async (data, callback) => {
    axios({
      method: "POST",
      url: BASE_URL + "/user/multiple/delete",
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {})
  },
  suspendUser: async (data, callback) => {
    axios({
      method: "POST",
      url: BASE_URL + "/user/multiple/suspend",
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {})
  },
  offerList: async (n, search, sort, filter, geoFilter, callback) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/offers/all?page=${n}&limit=${50}&search=${search}&sort=${sort}&status=${filter}&geoLocation=${geoFilter}`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
    })
      .then(response => {
        return response
      })
      .then(res => {
        return callback(res)
      })
      .catch(err => {})
  },
  editOffer: async (id, callback) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/offer/details/${id}`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
    })
      .then(response => {
        return response
      })
      .then(res => {
        return callback(res)
      })
      .catch(err => {})
  },

  updateOffer: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/offer/update/details/`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {})
  },
  deleteOffer: async (data, callback) => {
    axios({
      method: "POST",
      url: BASE_URL + "/offer/multiple/delete",
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {})
  },
  transactionList: async (n, search, statusfilter, sort, callback) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/transactions/all?page=${n}&limit=${10}&search=${search}&status=${statusfilter}&sort=${sort}`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
    })
      .then(response => {
        return response
      })
      .then(res => {
        return callback(res)
      })
      .catch(err => {})
  },
  viewReceipt: async (id, callback) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/user/transaction/details/${id}`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
    })
      .then(response => {
        return response
      })
      .then(res => {
        return callback(res)
      })
      .catch(err => {})
  },

  addUser: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/user/add`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {})
  },

  editUser: async (id, callback) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/user/details/${id}`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
    })
      .then(response => {
        return response
      })
      .then(res => {
        return callback(res)
      })
      .catch(err => {})
  },

  updateUser: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/user/update/`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {})
  },

  getAllSettings: async callback => {
    axios({
      method: "GET",
      url: `${BASE_URL}/portal/settings`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
    })
      .then(response => {
        return response
      })
      .then(res => {
        return callback(res)
      })
      .catch(err => {})
  },

  updateAllSettings: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/portal/update/settings`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {})
  },

  updateUserStatus: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/user/update/status`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {})
  },

  updateOfferStatus: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/offer/update/status`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {
        callback(err)
      })
  },

  dashboardData: async (timeFrame, geolocation, OS, callback) => {
    axios({
      method: "GET",
      url:
        geolocation == "" && OS != ""
          ? `${BASE_URL}/dashboard?range=${timeFrame}&deviceType=${OS}`
          : OS == "" && geolocation != ""
          ? `${BASE_URL}/dashboard?range=${timeFrame}&geoLocation=${geolocation}`
          : OS == "" && geolocation == ""
          ? `${BASE_URL}/dashboard?range=${timeFrame}`
          : `${BASE_URL}/dashboard?range=${timeFrame}&deviceType=${OS}&geoLocation=${geolocation}`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
    })
      .then(response => {
        return response
      })
      .then(res => {
        return callback(res)
      })
      .catch(err => {})
  },
  partnersList: async (n, search, sort, statusFilter, geoFilter, callback) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/partners/all?page=${n}&limit=${10}&search=${search}&sort=${sort}&status=${statusFilter}&geoLocation=${geoFilter}`,
      // url: `https://api.partner.staging.prized.ly/admin/partners/all`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
    })
      .then(response => {
        return response
      })
      .then(res => {
        return callback(res)
      })
      .catch(err => {})
  },

  updatePartnerStatus: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/partner/update/status`,
      // url: `http://13.232.220.2/admin/partner/update/status`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {
        callback(err.response)
      })
  },

  addModerator: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/moderator/invite`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {
        callback(err.response)
      })
  },

  moderatorList: async (n, search, sort, statusFilter, geoFilter, callback) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/moderators/all?page=${n}&limit=${10}&search=${search}&sort=${sort}&status=${statusFilter}&geoLocation=${geoFilter}`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
    })
      .then(response => {
        return response
      })
      .then(res => {
        return callback(res)
      })
      .catch(err => {})
  },
  deleteModerator: async (data, callback) => {
    axios({
      method: "POST",
      url: BASE_URL + "/moderator/multiple/delete",
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {})
  },
  discoveryApproval: async (data, callback) => {
    axios({
      method: "POST",
      url: `https://api.staging.prized.ly/moderator/discoveries/update/status`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
      data: data,
    })
      .then(response => {
        return callback(response)
      })
      .catch(err => {})
  },

  discoveriesList: async (
    n,
    search,
    sort,
    statusFilter,
    geoFilter,
    callback
  ) => {
    axios({
      method: "GET",
      url: `https://api.staging.prized.ly/moderator/discoveries/all?page=${n}&limit=${10}&search=${search}&sort=${sort}&status=${statusFilter}&geoLocation=${geoFilter}`,
      headers: {
        Authorization: `Bearer Bearer ${JSON.parse(
          sessionStorage.getItem("token")
        )}`,
      },
    })
      .then(response => {
        return response
      })
      .then(res => {
        return callback(res)
      })
      .catch(err => {})
  },

  geolocation: async (data, callback) => {
    axios({
      method: "GET",
      url: `https://api.partner.staging.prized.ly/index/geolocation?search=${data}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then(response => {
        return response
      })
      .then(res => {
        return callback(res)
      })
      .catch(err => {
        return callback(err.response)
      })
  },

  selectedGeolocation: async (geoName, geoType, callback) => {
    axios({
      method: "GET",
      url: `https://api.partner.staging.prized.ly/index/geolocation/sort/data?name=${geoName}&type=${geoType}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then(response => {
        return response
      })
      .then(res => {
        return callback(res)
      })
      .catch(err => {
        return callback(err.response)
      })
  },
}

export default adminDataObject
