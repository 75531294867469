import { React, lazy, Suspense } from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
// Charts
import ChartsKnob from "../pages/Charts/charts-knob"
import ReCharts from "../pages/Charts/ReCharts"
import PartnersManagement from "pages/Components/Pages/Partners Management/PartnersManagement"
import AdminModeratorManagement from "pages/Components/Pages/Admin Moderator/AdminModerator"
import ModeratorManagement from "pages/Components/Pages/Moderator/Moderator"
import AddModerator from "pages/Components/Pages/Admin Moderator/AddModerator"
import Unauthorized from "pages/Components/Pages/Common/Unauthorized/Unauthorized.component"

//*************Lazy-Loading********************************//
const UserManagement = lazy(() =>
  import("pages/Components/Pages/User Management/UserManagement")
)
const OffersManagement = lazy(() =>
  import("pages/Components/Pages/Offers Management/OffersManagement")
)
const PaymentManagement = lazy(() =>
  import("pages/Components/Pages/Payment Management/PaymentManagement")
)
const Settings = lazy(() => import("pages/Components/Pages/Settings/Settings"))
const AddUser = lazy(() =>
  import("pages/Components/Pages/User Management/AddUser")
)
const EditUser = lazy(() =>
  import("pages/Components/Pages/User Management/EditUser")
)
const LandingPage = lazy(() =>
  import("pages/Components/Pages/Landing-Page/LandingPage")
)
const PrivacyPolicy = lazy(() =>
  import("pages/Components/Pages/Landing-Page/PrivacyPolicy")
)
const ServiceTerms = lazy(() =>
  import("pages/Components/Pages/Landing-Page/ServiceTerms")
)
const PaymentReceipt = lazy(() =>
  import("pages/Components/Pages/Payment Management/PaymentReceipt")
)
const EditOffer = lazy(() =>
  import("pages/Components/Pages/Offers Management/EditOffer")
)

var authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/user_management", component: UserManagement },
  { path: "/discoveries_management", component: OffersManagement },
  { path: "/payment_management", component: PaymentManagement },
  { path: "/partners_management", component: PartnersManagement },
  { path: "/admin_moderator_management", component: AdminModeratorManagement },
  { path: "/moderator_management", component: Unauthorized },
  { path: "/settings", component: Settings },
  { path: "/user_management/add_user", component: AddUser },
  { path: "/moderator_management/add_moderator", component: AddModerator },
  { path: "/user_management/edit_user/:userId", component: EditUser },
  {
    path: "/payment_management/view_receipt/:transactionId",
    component: PaymentReceipt,
  },
  {
    path: "/discoveries_management/edit_offer/:offerId",
    component: EditOffer,
  },

  //Charts
  { path: "/charts-knob", component: ChartsKnob },
  { path: "/re-charts", component: ReCharts },
]
const moderatorRoutes = [
  { path: "/dashboard", component: Unauthorized },
  { path: "/user_management", component: Unauthorized },
  { path: "/discoveries_management", component: Unauthorized },
  { path: "/payment_management", component: Unauthorized },
  { path: "/partners_management", component: Unauthorized },
  { path: "/admin_moderator_management", component: Unauthorized },
  { path: "/moderator_management", component: ModeratorManagement },
  { path: "/settings", component: Unauthorized },
  { path: "/user_management/add_user", component: Unauthorized },
  { path: "/moderator_management/add_moderator", component: Unauthorized },
  { path: "/user_management/edit_user/:userId", component: Unauthorized },
  {
    path: "/payment_management/view_receipt/:transactionId",
    component: Unauthorized,
  },
  {
    path: "/discoveries_management/edit_offer/:offerId",
    component: Unauthorized,
  },
]

const publicRoutes = [
  { path: "/", component: Login },
  { path: "/landing", component: LandingPage },
  { path: "/privacy_policy", component: PrivacyPolicy },
  { path: "/terms_of_service", component: ServiceTerms },
  { path: "/", component: Login },
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
]

export { authProtectedRoutes, publicRoutes, moderatorRoutes }
