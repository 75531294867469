import { React, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import "./FeaturedInfo.css"
import PropTypes from "prop-types"
import { GroupIcon, People } from "../Pages/Icons/Icons"

import moment from "moment"
import { withTranslation } from "react-i18next"
import { TotalWinners } from "../Pages/Icons/Icons"
import coinsLogo from "../Assets/Images/coins_dashboard.png"
function FeaturedInfo({ data }) {
  const [totalStudents, setTotalStudents] = useState("")
  const [totalTeachers, setTotalTeachers] = useState("")
  const [totalParents, setTotalParents] = useState("")

  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD")
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD")
  const history = useHistory()

  return (
    <>
      <div className="featured">
        <div className="featuredItem">
          <div className="featuredMainContainer">
            <div className="featuredMoneyContainer">
              <span className="featuredTitle">
                {"Total users (registered)"}
              </span>
              <span className="featuredMoney">{data.totalUsers}</span>
              {/* <span className="featuredMoneyRate">{"12%"}</span> */}
            </div>
            <GroupIcon className="featuredIcon " />
          </div>
        </div>
        <div className="featuredItem">
          <div className="featuredMainContainer">
            <div className="featuredMoneyContainer">
              <span className="featuredTitle">{"Total users (active)"}</span>
              <span className="featuredMoney">{data.totalActiveUsers}</span>
              {/* <span className="featuredMoneyRate">{"8%"}</span> */}
            </div>
            <People className="featuredIcon " />
          </div>
        </div>

        <div className="featuredItem">
          <div className="featuredMainContainer">
            <div className="featuredMoneyContainer">
              <span className="featuredTitle">
                {"Total users (unregistered)"}
              </span>
              <span className="featuredMoney">
                {data.totalUnregisteredUsers}
              </span>
              {/* <span className="featuredMoneyRate">{"12%"}</span> */}
            </div>
            <People className="featuredIcon " />
          </div>
        </div>
        <div className="featuredItem">
          <div className="featuredMainContainer">
            <div className="featuredMoneyContainer">
              <span className="featuredTitle">{"Total Winners"}</span>
              <span className="featuredMoney">{data.totalWinners}</span>
              {/* <span className="featuredMoneyRate">{"8%"}</span> */}
            </div>
            <TotalWinners className="featuredIcon " />
          </div>
        </div>
      </div>
      <br />
      <div className="featured">
        <div className="featuredItem">
          <div className="featuredMainContainer">
            <div className="featuredMoneyContainer">
              <span className="featuredTitle">{"Total points generated"}</span>
              <span className="featuredMoney">
                {Math.round(data.totalPointsGenerated, 0)}
              </span>
              {/* <span className="featuredMoneyRate">{"12%"}</span> */}
            </div>
            <img src={coinsLogo} className="featuredLogo " />
          </div>
        </div>
        <div className="featuredItem">
          <div className="featuredMainContainer">
            <div className="featuredMoneyContainer">
              <span className="featuredTitle">{"Total points played"}</span>
              <span className="featuredMoney">{data.totalPointsPlayed}</span>
            </div>
            <img src={coinsLogo} className="featuredLogo " />
          </div>
        </div>

        <div className="featuredItem">
          <div className="featuredMainContainer">
            <div className="featuredMoneyContainer">
              <span className="featuredTitle">{"Total points won"}</span>
              <span className="featuredMoney">{0}</span>
            </div>
            <img src={coinsLogo} className="featuredLogo " />
          </div>
        </div>
        <div className="featuredItem">
          <div className="featuredMainContainer">
            <div className="featuredMoneyContainer">
              <span className="featuredTitle">{"Total points collected"}</span>
              <span className="featuredMoney">{data.totalPointsReceived}</span>
            </div>
            <img src={coinsLogo} className="featuredLogo " />
          </div>
        </div>
      </div>
    </>
  )
}
FeaturedInfo.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
}

export default withTranslation()(FeaturedInfo)
