import { all, fork } from "redux-saga/effects"

//public
import LayoutSaga from "./layout/saga"
import LoginSaga from "./auth/Prizedly_Login/saga"

export default function* rootSaga() {
  yield all([
    //public
    // fork(AuthSaga),

    fork(LayoutSaga),

    fork(LoginSaga),
  ])
}
