import React, { useEffect, useState } from "react"
import { Label } from "reactstrap"
import "../../Common_Style/searchbar/Searchbar.css"
import adminDataObject, {
  partnerDataObject,
} from "pages/Components/Prizedly-Controller/Controller"
import PropTypes from "prop-types"
import { RxCross2 } from "react-icons/rx"
import { FaSearch } from "react-icons/fa"

function CountriesSearchbar({
  handler,
  geolocationData,
  viewCountries,
  editType,
  disable,
}) {
  console.log("viewCountries==>", viewCountries, "geo==>", geolocationData)
  const [input, setInput] = useState("")
  const [show, setShow] = useState(false)
  const [geoData, setGeoData] = useState([])
  const [selectedGeoData, setSelectedGeoData] = useState([])
  const [searchedData, setSearchedData] = useState([])
  const [formattedGeoData, setFormattedGeoData] = useState({})

  useEffect(() => {
    console.log("inside useEffect==>", geolocationData)
    if (geolocationData) {
      setFormattedGeoData(geolocationData)
      setSearchedData(viewCountries)
    }
  }, [viewCountries])

  useEffect(() => {
    console.log({ input })
    if (input.length >= 3) {
      getGeoData(), setShow(true)
    }
  }, [input])

  const getGeoData = () => {
    adminDataObject.geolocation(input, result => {
      console.log("geoResult==>", result)
      setGeoData(result.data.data)
    })
  }

  useEffect(() => {
    geolocationReformatHandler()
  }, [selectedGeoData])

  const handleChange = val => {
    setInput(val)
    if (val.length < 3) {
      setShow(false)
    }
  }
  const selectedGeolocationHandler = (name, type) => {
    adminDataObject.selectedGeolocation(name, type, result => {
      console.log("selectedGeoResult==>", result)
      setSelectedGeoData([...selectedGeoData, result.data.data])
    })
  }

  const geolocationReformatHandler = () => {
    const uniqueCountry = [
      ...new Set(selectedGeoData.map(item => item.country_code)),
    ]
    const uniqueStates = [...new Set(selectedGeoData.map(item => item.state))]
    const uniqueCities = [...new Set(selectedGeoData.map(item => item.city))]

    const country_code = uniqueCountry.map(country_code => ({ country_code }))
    const state_name = uniqueStates.map(name => ({ name }))
    const city_name = uniqueCities.map(name => ({ name }))

    let filteredCountry_Code = country_code.filter(obj => obj !== undefined)
    let filteredStates = state_name.filter(obj => obj.name !== undefined)
    let filteredCities = city_name.filter(obj => obj.name !== undefined)

    console.log("country123==>", filteredCountry_Code)
    setFormattedGeoData({
      countries: filteredCountry_Code,
      states: filteredStates,
      cities: filteredCities,
    })
  }
  const removeFromFormattedGeoData = (name, index) => {
    console.log("iiiiinnnnn==>", formattedGeoData)
    let countries = formattedGeoData.countries.filter(
      item => item.country_name !== name
    )
    let states = formattedGeoData.states.filter(item => item.name !== name)
    let cities = formattedGeoData.cities.filter(item => item.name !== name)
    console.log("country==>", countries, "state==>", states, "city==>", cities)
    return setFormattedGeoData({
      countries: countries,
      states: states,
      cities: cities,
    })
  }
  console.log("after::", formattedGeoData)
  const geoDetailsHandler = selectedData => {
    setShow(false)
    setInput("")
    setSearchedData([...searchedData, selectedData.name])
    selectedGeolocationHandler(selectedData.name, selectedData.type)
  }
  const removeCountryHandler = (s, i) => {
    console.log("remove==>")
    searchedData.splice(i, 1)
    selectedGeoData.splice(i, 1)
    removeFromFormattedGeoData(s, i)
    setSearchedData([...searchedData])
    setSelectedGeoData([...selectedGeoData])
  }
  console.log("formatedGeoData==>", formattedGeoData, "@==>", searchedData)

  useEffect(() => {
    if (formattedGeoData) {
      handler(formattedGeoData, searchedData)
    }
  }, [formattedGeoData])
  return (
    <div
      className="discovery-search-container"
      style={{ position: "relative" }}
    >
      <Label className="module-label">Geography</Label>
      <div className="countriesList-container">
        {
          searchedData?.map((s, i) => {
            return (
              <div className="country-container" key={i}>
                {s}
                {editType !== "duplicate" && (
                  <RxCross2
                    style={{ fontSize: "15px", cursor: "pointer" }}
                    onClick={() => removeCountryHandler(s, i)}
                  />
                )}
              </div>
            )
          })
          // .slice(0, 3)
        }
        {/* {searchedData?.length > 3 && (
          <div className="more-options">{`+ ${
            searchedData?.length - 3
          } more`}</div>
        )} */}
      </div>
      <div className="input-wrapper">
        <input
          placeholder="Search country or cities"
          id="geoInput"
          value={input}
          disabled={disable}
          onChange={e => handleChange(e.target.value)}
        />
        <FaSearch id="search-icon" />
      </div>
      {show && (
        <div className="results-list">
          {geoData?.length > 0 &&
            geoData.map((res, id) => {
              return (
                <div
                  className="search-result"
                  key={id}
                  onClick={() => geoDetailsHandler(res)}
                >
                  {res.name}
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}
CountriesSearchbar.propTypes = {
  handler: PropTypes.func,
  viewCountries: PropTypes.any,
  geolocationData: PropTypes.any,
  editType: PropTypes.string,
  disable: PropTypes.bool,
}

export default CountriesSearchbar
