import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import "../../App.css"
import { Route, Redirect } from "react-router-dom"
import { useHistory } from "react-router-dom"
import swal from "sweetalert2"

//redux
import { useSelector, useDispatch } from "react-redux"

// import images

import "react-toastify/dist/ReactToastify.css"
import Prizedly_logo from "../../pages/Components/Assets/Images/Prizedly.png"
import "../../App.css"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { MdEmail } from "react-icons/md"
import adminDataObject from "pages/Components/Prizedly-Controller/Controller"

//Import config
import { withTranslation } from "react-i18next"
import Swal from "sweetalert2"

function Login(props) {
  console.log("loginProps==>", props)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [show, setShow] = useState(false)
  const [isAdminChecked, setIsAdminChecked] = useState(false)
  const [isModeratorChecked, setIsModeratorChecked] = useState(false)

  const loginResponse = useSelector(state => state.authAdmin)
  const dispatch = useDispatch()
  const history = useHistory()
  const emailRegex =
    /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i

  let emailErrorCheck = false
  let PasswordErrorCheck = false
  const LoginHandler = () => {
    if (email === "") {
      setEmailError("Please enter the email address")
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email")
    } else {
      setEmailError("")
      emailErrorCheck = true
    }
    if (password === "") {
      setPasswordError("Please enter the password")
    } else if (password.length < 8) {
      setPasswordError("Password must have atleast 8 Characters")
    } else {
      setPasswordError("")
      PasswordErrorCheck = true
    }
    const body = {
      email: email,
      password: password,
      deviceId: "123456",
      deviceType: "ios",
    }
    if (emailErrorCheck && PasswordErrorCheck) {
      if (isAdminChecked) {
        adminDataObject.adminLogin(body, result => {
          if (result.status == 200) {
            swal.fire({
              title: "",
              text: `Login Successfully`,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            })
            sessionStorage.setItem(
              "token",
              JSON.stringify(result.data.data.loginToken)
            )
            sessionStorage.setItem(
              "role",
              JSON.stringify(result.data.data.roles[0])
            )
            props.roleHandler(result.data.data.roles[0])
            history.push("/dashboard")
          } else {
            swal.fire({
              title: "",
              text: `Invalid Credentials`,
              icon: "warning",
              timer: 2000,
              showConfirmButton: false,
            })
          }
        })
      } else if (isModeratorChecked) {
        const body = {
          email: email,
          password: password,
        }
        adminDataObject.moderatorLogin(body, result => {
          if (result.status == 200) {
            swal.fire({
              title: "",
              text: `Login Successfully`,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            })
            sessionStorage.setItem(
              "token",
              JSON.stringify(result.data.data.loginToken)
            )
            sessionStorage.setItem(
              "role",
              JSON.stringify(result.data.data.roles[0])
            )
            props.roleHandler(result.data.data.roles[0])
            history.push("/moderator_management")
          } else {
            swal.fire({
              title: "",
              text: `Invalid Credentials`,
              icon: "warning",
              timer: 2000,
              showConfirmButton: false,
            })
          }
        })
      } else {
        Swal.fire({
          title: "",
          text: `Please select the login type`,
          icon: "warning",
          timer: 2000,
          showConfirmButton: false,
        })
      }
    }
  }
  const toggleButton = () => {
    setShow(prevState => !prevState)
  }
  console.log("roleCheck==>", isAdminChecked, "@@@@@==>", isModeratorChecked)
  return (
    <React.Fragment>
      <MetaTags>
        <title>Login</title>
      </MetaTags>
      <div className="App">
        <div className="registerLogin">
          <form id="formLogin">
            <img
              src={Prizedly_logo}
              style={{ width: "285px", height: "130px" }}
            />
            <br />
            <br />
            <br />
            <div className="">
              <Form className="">
                <div className="fields">
                  <Label className="labelltrClass">Email</Label>
                  <div className="password">
                    <Input
                      name="email"
                      className="form-control inputFieldColor"
                      placeholder="Enter your email"
                      type="email"
                      onChange={e =>
                        setEmail(e.target.value.replace(/\s/g, ""))
                      }
                      value={email}
                    />
                    <div className="fieldIcon">
                      <MdEmail />
                    </div>
                  </div>
                  {emailError && (
                    <p style={{ color: "red", textAlign: "left" }}>
                      {props.t(emailError)}
                    </p>
                  )}
                </div>
                <br />
                <div className="fields">
                  <Label className="labelltrClass">Password</Label>
                  <div className="password">
                    <Input
                      className="inputFieldColor"
                      name="password"
                      type={show ? "text" : "password"}
                      placeholder="Password"
                      onChange={e =>
                        setPassword(e.target.value.replace(/\s/g, ""))
                      }
                      value={password}
                    />
                    <button
                      type="button"
                      className="passwordBtn"
                      onClick={toggleButton}
                    >
                      {show ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </button>
                  </div>
                  {passwordError && (
                    <p style={{ color: "red", textAlign: "left" }}>
                      {props.t(passwordError)}
                    </p>
                  )}
                </div>
                <div className="role-container">
                  <div style={{ marginBottom: "5px" }}>{"Login as:"}</div>
                  <div className="role-subContainer">
                    <div className="role-checkbox-container">
                      <input
                        type="checkbox"
                        onChange={event =>
                          setIsAdminChecked(event.target.checked)
                        }
                      />
                      {"Admin"}
                    </div>
                    <div className="role-checkbox-container">
                      <input
                        type="checkbox"
                        onChange={event =>
                          setIsModeratorChecked(event.target.checked)
                        }
                      />
                      {"Moderator"}
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <button
                  className="btnLgIn"
                  type="button"
                  onClick={LoginHandler}
                >
                  Login
                </button>
              </Form>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

Login.propTypes = {
  // history: PropTypes.object,
  t: PropTypes.any,
  roleHandler: PropTypes.func,
}

export default withTranslation()(Login)
